import { render, staticRenderFns } from "./ProductAddSuccess.vue?vue&type=template&id=1b5c8a5b&scoped=true&"
import script from "./ProductAddSuccess.vue?vue&type=script&lang=js&"
export * from "./ProductAddSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ProductAddSuccess.vue?vue&type=style&index=0&id=1b5c8a5b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5c8a5b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnTag: require('/home/php1/m-nuxt/components/YfnTag.vue').default,YfnRate: require('/home/php1/m-nuxt/components/YfnRate.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default,YfnBag: require('/home/php1/m-nuxt/components/YfnBag.vue').default,CustomizeIframe: require('/home/php1/m-nuxt/components/CustomizeIframe.vue').default})
